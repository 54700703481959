/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'action': {
    width: 16,
    height: 12,
    viewBox: '0 0 16 12',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M0 1.314a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H.75a.75.75 0 01-.75-.75zm0 4.5a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H.75a.75.75 0 01-.75-.75zm.75 3.75a.75.75 0 000 1.5h14.5a.75.75 0 000-1.5H.75z" _fill="#898B9B"/>'
  }
})
